import React, { useEffect } from 'react';
import CertificateCard from '../../Components/CertificateCard/CertificateCard';
import imgISO9001 from '../../assets/Certificates/ISO-9001-2015-New Certificate-2022-2025 1.jpg';
import BBBEE from '../../assets/Certificates/Level 1 BBBEE.jpg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive'; // Import useMediaQuery hook



function Certificates() {
    const isLaptop = useMediaQuery({ minWidth: 1024 }); // Define the breakpoint for laptop screen size
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        AOS.init({
            // Global settings:
            disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
            startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
            initClassName: 'aos-init', // class applied after initialization
            animatedClassName: 'aos-animate', // class applied on animation
            useClassNames: false, // if true, will add content of `data-aos` as classes on scroll

            // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
            offset: 320, // offset (in px) from the original trigger point
            delay: 0, // values from 0 to 3000, with step 50ms
            duration: 300, // values from 0 to 3000, with step 50ms
            easing: 'ease', // default easing for AOS animations
            once: false, // whether animation should happen only once - while scrolling down
            mirror: false, // whether elements should animate out while scrolling past them
            anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top on component mount
    }, []);

    return (
        <div >
            <div data-aos={isMobile ? "fade-up" : "fade-left"}>
                <CertificateCard title={"ISO-9001-2015-New Certificate-2022-2025 1"} description={"Accreditation for ISO 9001:2015 standards, affirming commitment to quality management systems, enhancing organizational efficiency, customer satisfaction and global market competitiveness."} imageUrl={imgISO9001} />
            </div>
            <div data-aos={isMobile ? "fade-up" : "fade-right"} >
                <CertificateCard title={"Level 1 BBBEE supplier "} description={"A Level 1 BBBEE contributor with 30%+1 black ownership."} imageUrl={BBBEE} />
            </div>

        </div>
    );
}

export default Certificates;